<template>
  <div :class="{ 'shrinkreport-content': drawer && !isMobile }" style="margin-top:65px;">
    <br/>

    <v-container>

      <h3>{{ $t("mobile.header_1") }}</h3>
      <h5>{{ $t("mobile.header_2") }}</h5>
      <br/>
      <v-row>        
        <v-col md="6" cols="12">
          <v-card style="text-align:center;">
            <v-icon x-large style="color:rgba(0, 0, 0, 0.54);font-size:100px;display:block;max-width:50%;margin:auto;">mdi-android</v-icon>
            <h2>Android</h2>
            <h5>{{ $t('mobile.steps_to_follow') }}</h5>

            <ol style="text-align:left;margin:10px;">
              <li>
                {{ $t('mobile.android_step_1') }}<br/>
                <img :src="'/assets/img/mobile-android-01-' + $t('general.i18n') + '.webp'" class="mobileStep" width="250" />
              </li>
              <li>
                {{ $t('mobile.android_step_2') }}
                <a style="margin-left:10px;" target="_blank" href="https://play.google.com/store/apps/details?id=com.technopieux.app40"><img class="mobileStep" width="250" src="/assets/img/qr_android.png"/>https://play.google.com/store/apps/details?id=com.technopieux.app40</a>
              </li>
              <li>
                {{ $t('mobile.android_step_3') }}
                <img :src="'/assets/img/mobile-android-03-' + $t('general.i18n') + '.webp'" class="mobileStep" width="250" />
              </li>
              <li>
                {{ $t('mobile.android_step_4') }}
                <img :src="'/assets/img/mobile-android-04-' + $t('general.i18n') + '.webp'" class="mobileStep" width="250" />
              </li>
              <li>
                {{ $t('mobile.android_step_5') }}
                <img :src="'/assets/img/mobile-android-05-' + $t('general.i18n') + '.webp'" class="mobileStep" width="250" />
              </li>
            </ol>
            <br/>
          </v-card>
        </v-col>

        <v-col md="6" cols="12">
          <v-card style="text-align:center;">
            <v-icon x-large style="color:rgba(0, 0, 0, 0.54);font-size:100px;display:block;max-width:50%;margin:auto;">mdi-apple-ios</v-icon>
            <h2>Apple</h2>
            <h5>{{ $t('mobile.steps_to_follow') }}</h5>

            <ol style="text-align:left;margin:10px;">
              <li>
                {{ $t('mobile.ios_step_01') }}
                <img :src="'/assets/img/mobile-ios-01-' + $t('general.i18n') + '.webp'" class="mobileStep" width="250" />
              </li>
              <li>
                {{ $t('mobile.ios_step_01_01') }}
                <a style="margin-left:10px;" target="_blank" href="https://apps.apple.com/app/tp-360/id1618437181/"><img class="mobileStep" width="250" src="/assets/img/qr_ios.png">https://apps.apple.com/app/tp-360/id1618437181/</a>
              </li>              
              <li>
                {{ $t('mobile.ios_step_02') }}
                <img :src="'/assets/img/mobile-ios-02-' + $t('general.i18n') + '.webp'" class="mobileStep" width="250" />
              </li>
              <li>
                {{ $t('mobile.ios_step_03') }}
                <img :src="'/assets/img/mobile-ios-03-' + $t('general.i18n') + '.webp'" class="mobileStep" width="250" />
              </li>
              <li>
                {{ $t('mobile.ios_step_04') }}
                <img :src="'/assets/img/mobile-ios-04-' + $t('general.i18n') + '.webp'" class="mobileStep" width="250" />
              </li>
            </ol>
            <br/>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MobileApp",
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer
    }),
  },
  created() {
   
  },
  methods: {
   
  }
};
</script>

<style scoped>
li + li {
  margin-top: 30px;
}

img.mobileStep{
  display:block;
  border:1px solid #000;
}
</style>